import { createRouter, createWebHistory } from "vue-router";
import TheHomepage from "./components/TheHomepage.vue";
import WholeProperty from "./pages/WholeProperty.vue";
import NewBuildings from "./pages/NewBuildings.vue";
import ForSale from "./pages/ForSale.vue";
import ForRent from "./pages/ForRent.vue";
import AboutUs from "./pages/AboutUs.vue";
import NotFound from "./components/ui/NotFound.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", component: TheHomepage },
    { path: "/new-buildings", component: NewBuildings },
    { path: "/for-sale", component: ForSale },
    { path: "/property/:id", name: "WholeProperty", component: WholeProperty },
    { path: "/for-rent", component: ForRent },
    { path: "/about-us", component: AboutUs },
    { path: "/:notFound(.*)", component: NotFound },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { left: 0, top: 0}
  }
});

export default router;
