<template>
  <div class="whole-property-wrapper">
    <div v-if="property">
      <the-breadcrumbs
        :estate-type-name="property.estateTypeName"         
        :region="property.regionName"
        :sub-region="property.subRegionName"
      ></the-breadcrumbs>
    </div>

    <div v-if="property">
      <property-gallery-card
        :pictures="property.images"
      ></property-gallery-card>
    </div>

    <div v-if="property">
      <property-heading-card
        :price="property.price"
        :price-per-sqm="property.pricePerM"
        :bedrooms="property.bedroomCount"
        :bathrooms="property.bathCount"
        :toilets="property.toiletCount"
        :balconies="property.balconyCount"
        :property-type="property.estateTypeName"
        :building-type="property.buildTypeName"
        :heating-type="property.heatingNames"
        :surface="property.surfaceAll"
        :builded="property.estateBuilded"
        :region="property.regionName"
        :sub-region="property.subRegionName"
      ></property-heading-card>
    </div>

    <div v-if="property">
      <property-description-card
        :description="property.descriptionBG"
        :agencyName="property.agencyName"
        :companyEmail="property.companyEmail"
        :companyPhone="property.companyPhone"
        :brokers="brokers"
      ></property-description-card>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import PropertyHeadingCard from "@/components/property-cards/PropertyHeadingCard.vue";
import PropertyGalleryCard from "@/components/property-cards/PropertyGalleryCard.vue";
import PropertyDescriptionCard from "@/components/property-cards/PropertyDescriptionCard.vue";

export default {
  components: {
    PropertyGalleryCard,
    PropertyHeadingCard,
    PropertyDescriptionCard,
  },
  data() {
    return {
      estatePreviewData: [],
      property: null,
      brokers: [],
    };
  },
  watch: {
    $route(to, from) {
      if (to.params.id !== from.params.id) {
        this.fetchEstatePreview(to.params.id);
      }
    },
  },
  methods: {
    async fetchEstatePreview() {
      try {
        const response = await axios.get(
          `https://api.estateassistant.eu/api/portals/GetEstatePreviewByID/?id=${this.routeID}`
        );
        this.estatePreviewData = response.data;
        this.property = response.data.data;
        this.brokers = response.data.brokers;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
  },

  computed: {
    routeID: function () {
      return this.$route.params.id;
    },
  },
  mounted() {
    this.fetchEstatePreview();
  },
};
</script>

<style lang="scss" scoped></style>
