<template>
  <div>
    <header>
    <div class="desktop-navigation-wrapper">
      <div class="desktop-navigation-wrapper__logo-wrapper">
        <the-logo></the-logo>
      </div>
      <div>
        <DesktopNavigation/>
      </div>
    </div>
    </header>
  </div>
</template>

<script>
    import DesktopNavigation from './DesktopNavigation.vue'
export default {
    components: {
        DesktopNavigation,
    }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/main.scss";
</style>
