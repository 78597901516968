<!-- Card element for displaying new buldings, differen ptops and information (price ranges, surface ranges and etc.) -->
<template>
  <div class="property-wrapper">
    <div class="property-box">
      <!-- Swiper starts here -->
      <div class="swiper-wrapper">
        <swiper
          :loop="true"
          navigation
          :pagination="{ clickable: true }"
          :scrollbar="{ draggable: true }"
          class="property-slider"
        >
          <swiper-slide v-for="(image, index) in pictures" :key="index">
            <router-link
              :to="'/property/' + id"
              :style="'background-image:url(' + image + ')'"
              class="background bg-size"
            >
              <img :src="image" alt="property image" />
            </router-link>
          </swiper-slide>
        </swiper>
      </div>
      <!-- Swiper ends here -->
      <div class="property-details">
        <span class="property-details__location-span">{{ region }}</span>
        <div class="property-details__go-to">
          <router-link :to="'/property/' + id">
            <h3>{{ heading }}</h3>
          </router-link>
        </div>

        <h6><span>Цена от {{ minPriceInEur }} до {{ maxPriceInEur }} EUR </span></h6>
        <p class="font-class">
          {{ slicedDescription }}...
        </p>
        <ul>
          <li>
            <el-icon><DataBoard /></el-icon> Квадратура от {{ minAreaSize }} до {{ maxAreaSize }} кв.м.
          </li>
          <li>
            <el-icon><Location /></el-icon> Регион: {{ subRegion }} 
          </li>
        </ul>
        <div class="property-details__property-btn">
          <!-- <span class="property-details__timestamp">{{ timestamp }}</span> -->
          <router-link :to="'/property/' + id"> Детайли </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: [
    "id",
    "region",
    "heading",
    "address",
    "description",
    "minPriceInEur",
    "maxPriceInEur",
    "minAreaSize",
    "maxAreaSize",
    "pictures",
    "offerType",
    "subRegion"
  ],
  data() {
    return {
      swiperOptions: {
        on: {
          init: this.onSwiper,
          slideChange: this.onSlideChange,
        },
      },
    };
  },
    computed: {
    slicedDescription() {
      return this.description.slice(0, 200);
    },
  },
  methods: {
    // onSwiper(swiper) {
    // },
    // onSlideChange() {
    // },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/main.scss";
</style>
