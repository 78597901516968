<template>
    <div>
        <whole-property-listing></whole-property-listing>
    </div>
</template>

<script>
import WholePropertyListing from "../layouts/WholePropertyListing.vue"
export default {
    components: {
        WholePropertyListing,
    }
}
</script>