<template>
<div>
    <h1>Ново строителство</h1>
    <div>
        <new-buildings-listing></new-buildings-listing>
    </div>
</div>
</template>

<script>
import NewBuildingsListing from '../layouts/NewBuildingsListing.vue' 
export default {
    components: {
        NewBuildingsListing,
    }
}
</script>