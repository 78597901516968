<template>
  <div class="loading-element">
      <h2>Зареждане...</h2>
        <div class="progress">
          <el-progress :percentage="100" :format="format" :indeterminate="true" />
      </div>
  </div>
</template>

<script setup>
const format = (percentage) => (percentage === 100 ? `` : `${percentage}%`)
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/main.scss";


</style>